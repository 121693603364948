import classNames from 'classnames';
import { push } from 'connected-react-router';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TextField from '@material-ui/core/TextField';

import { Accordion } from '.';
import { toggleFilter_ac } from '../../actions/me.ac.js';
import DateUtil from '../../util/dateProcessing';
import UserSearch from '../Users/UserSearch';
import SuperReactDatePicker from './SuperReactDatePicker';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  label: {
    color: theme.palette.primary.main,
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  scrollArea: {
    maxHeight: 'calc(100vh - 100px)',
    overflowY: 'auto',
  },
  filterAction: {},
  filterRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    padding: '12px',
  },
  formNode: {
    display: 'inline-block',
    minWidth: '250px',
    flexGrow: 1,
  },
  checkboxGroupRow: {
    flexDirection: 'unset',
  },
  checkboxGroupRowItem: {},
  drawerPaper: {},
  drawerPaperClose: {},
  toolbarIcon: {},
  pagination: {
    marginTop: 0,
    padding: '8px',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    fontWeight: 'normal',
  },
});

export const FILTER_MODES = {
  ACCORDION: 'ACCORDION',
  TOP_SWIPE: 'TOP_SWIPE',
  DEFAULT: 'DEFAULT',
};

const Wrapper = ({ children, ...rest }) => <div {...rest}>{children}</div>;

const FilterPanel = (props) => {
  let _t = {};
  let _textValues = {};
  if (props.filterSequence) {
    props.filterSequence.forEach((f) => {
      switch (f.kind) {
        case 'text':
          //_textValues[f]="";
          break;
        default:
          _t[f] = true;
          break;
      }
    });
  }

  const [state, setState] = React.useState({
    toggle: _t,
    textValues: _textValues,
  });

  const toggleSelectAll = (fname) => (e) => {
    if (typeof props.onFilterToggle !== 'function') return;

    setState({
      toggle: { ...state.toggle, [fname]: !state.toggle[fname] },
    });
    props.onFilterToggle(fname, !state.toggle[fname]);
  };

  const isInvalidForm = () => {
    return Object.keys(props.filters).some((item) => props.filters[item].error);
  };

  const onUserRemoveAll = (filterName) => () => {
    props.onUserFilterUpdated(filterName, []);
  };

  const onUserRemove = (filterName, email) => () => {
    let idx = props.filters[filterName].values.findIndex(
      (v) => v.email === email
    );
    if (idx === -1) return;
    let _values = [...props.filters[filterName].values];
    _values.splice(idx, 1);
    props.onUserFilterUpdated(filterName, _values);
    return;
  };

  const onUserSelected = (filterName) => (e) => {
    if (
      props.filters[filterName].values.findIndex((v) => v.email === e.email) >
      -1
    ) {
      return; //already listed
    }

    let _values = [
      ...props.filters[filterName].values,
      { nm: e.orig_nm, email: e.email },
    ];
    props.onUserFilterUpdated(filterName, _values);
  };

  const open = () => {
    props.toggleFilter_ac(true);
  };

  const close = () => {
    props.toggleFilter_ac(false);
  };

  const isCheckBoxDisabled = (name) => {
    const checkedItemLength = props.filters[name].options.filter(
      (option) => option.checked
    ).length;
    return checkedItemLength >= props.filters[name].maxChecked;
  };

  const renderFilter = () => {
    const { classes } = props;
    const { filters, filterSequence } = props;
    const limitYear = new Date().setFullYear(new Date().getFullYear() - 2);

    return (
      <div className={classes.filterRoot}>
        {filterSequence.map((fName, fidx) => {
          const f = filters[fName];

          switch (f.kind) {
            case 'text':
              return (
                <div key={fidx} className={classes.formNode}>
                  <label
                    onClick={props.onFilterClearField(fName)}
                    className={classes.label}>
                    {f.lbl} [Clear]
                  </label>
                  <FormGroup className={classes.checkboxGroupRow}>
                    <TextField
                      id="outlined-full-width-text"
                      style={{ margin: '8px 8px 8px 0' }}
                      placeholder={f.placeholder || ''}
                      fullWidth
                      margin="normal"
                      value={f.value}
                      onChange={props.onFilterChange(fName)}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormGroup>
                </div>
              );
            case 'users':
              return (
                <div key={fidx} className={classes.formNode}>
                  <label
                    onClick={onUserRemoveAll(fName)}
                    className={classes.label}>
                    {f.lbl} [Clear]
                  </label>
                  <FormGroup className={classes.checkboxGroupRow}>
                    <div>
                      {f.values.map((v) => (
                        <div
                          onClick={onUserRemove(fName, v.email)}
                          style={{ cursor: 'pointer', fontSize: 11 }}
                          key={v.email}>
                          [-] {v.nm}
                        </div>
                      ))}
                    </div>
                    <UserSearch
                      onSelect={onUserSelected(fName)}
                      includeDisabled
                    />
                  </FormGroup>
                </div>
              );
            case 'date':
              return (
                <div
                  key={fidx}
                  className={classes.formNode}
                  style={{ marginRight: '6px' }}>
                  <label
                    onClick={props.onFilterClearField(fName)}
                    className={classes.label}>
                    {f.lbl} [Clear]
                  </label>
                  <FormGroup className={classes.checkboxGroupRow}>
                    <TextField
                      id="outlined-full-width-date"
                      style={{ margin: '4px 4px 4px 0' }}
                      error={f.dirty && !DateUtil.isValidDate(f.value)}
                      placeholder={f.placeholder || ''}
                      fullWidth
                      margin="normal"
                      value={f.value}
                      onChange={props.onFilterChange(fName)}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormGroup>
                </div>
              );
            case 'dateTime':
              return (
                <div
                  key={fidx}
                  className={classes.formNode}
                  style={{ marginRight: '6px' }}>
                  <label
                    onClick={props.onFilterClearField(fName)}
                    className={classes.label}>
                    {f.lbl} [Clear]
                  </label>
                  <FormGroup className={classes.checkboxGroupRow}>
                    <SuperReactDatePicker
                      minDate={limitYear}
                      selected={f.value ? new Date(f.value) : null}
                      dateFormat="MM-dd-yyyy hh:mm a"
                      onChange={(value) => {
                        props.onFilterChange(fName)({
                          target: {
                            value: value
                              ? moment(value).format('MM-DD-YYYY hh:mm a')
                              : '',
                          },
                        });
                      }}
                      customInput={
                        <TextField
                          id="outlined-full-width-dateTime"
                          placeholder={f.placeholder || ''}
                          fullWidth
                          required
                          margin="normal"
                          style={{ margin: '4px 4px 4px 0' }}
                        />
                      }
                      showMonthDropdown
                      showYearDropdown
                      showTimeSelect
                      timeFormat="p"
                      timeIntervals={30}
                      dropdownMode="select"
                      placeholderText="Choose a date/time"
                    />
                  </FormGroup>
                </div>
              );
            case 'radio':
              return (
                <div key={fidx} className={classes.formNode}>
                  <label className={classes.label}>{f.lbl}</label>
                  <RadioGroup
                    name={fName}
                    value={props.selected[fName]}
                    onChange={props.onRadioFilterChange}>
                    {f.options.map((t, pos) => (
                      <FormControlLabel
                        key={t.code}
                        control={<Radio />}
                        label={t.lbl}
                        value={t.code}
                      />
                    ))}
                  </RadioGroup>
                </div>
              );
            case 'labelOnly':
              return (
                <div key={fidx} className={classes.formNode}>
                  <label className={classes.label}>{f.lbl}</label>
                </div>
              );
            default:
              return (
                <div
                  key={fidx}
                  style={f.width ? { width: f.width } : {}}
                  className={classes.formNode}>
                  {typeof props.onFilterToggle !== 'function' ? (
                    <label className={classes.label}>{f.lbl}</label>
                  ) : (
                    <label
                      onClick={toggleSelectAll(fName)}
                      style={{ cursor: 'pointer' }}
                      className={classes.label}>
                      {f.lbl} [+/-]
                    </label>
                  )}
                  <FormGroup className={classes.checkboxGroupRow}>
                    {f.options.map((t, pos) => (
                      <FormControlLabel
                        className={classes.checkboxGroupRowItem}
                        key={t.code}
                        control={
                          <Checkbox
                            style={{ padding: '6px 6px 6px 12px' }}
                            checked={t.checked}
                            onChange={props.onFilterChange(fName)}
                            value={t.code || ''}
                            disabled={
                              (!t.checked && isCheckBoxDisabled(fName)) ||
                              f.disabled
                            }
                          />
                        }
                        label={t.lbl ? t.lbl : t.code}
                      />
                    ))}
                  </FormGroup>
                </div>
              );
          }
        })}
        <div
          className={classNames(classes.filterAction, classes.formNode)}
          style={{ width: '10%' }}>
          {props.applyFilters && (
            <div style={{ marginLeft: 12 }} className={classes.pagination}>
              <Button
                onClick={props.applyFilters}
                variant="outlined"
                disabled={isInvalidForm()}>
                Apply Filters
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const {
    classes,
    me,
    mode = FILTER_MODES.DEFAULT,
    wrapper = Wrapper,
    title = 'filter',
  } = props;

  const WRAP_CONFIG = {
    [FILTER_MODES.ACCORDION]: {
      wrapper: Accordion,
      props: {
        isExpanded: props.expanded || true,
        setIsExpanded: props.setExpanded,
        header: !props.noTitle && (
          <span className={classes.title}>{title}</span>
        ),
      },
    },
    [FILTER_MODES.DEFAULT]: {
      wrapper,
      props: {},
    },
    [FILTER_MODES.TOP_SWIPE]: {
      wrapper: SwipeableDrawer,
      props: {
        variant: 'temporary',
        anchor: 'left',
        onOpen: open,
        onClose: close,
        classes: {
          paper: classNames(
            classes.drawerPaper,
            !me.filterOpen && classes.drawerPaperClose
          ),
        },
        open: me.filterOpen,
      },
    },
  };

  const wrapConfig = WRAP_CONFIG[mode] || WRAP_CONFIG[FILTER_MODES.DEFAULT];

  const { wrapper: WrapperComponent, props: wrapperProps } = wrapConfig;

  return (
    <WrapperComponent
      {...wrapperProps}
      style={{ padding: 12, width: '100%' }}
      className={classes.scrollArea}>
      {renderFilter()}
    </WrapperComponent>
  );
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
});

const mapDispatchToProps = (dispatch, propsOfIdnLanding) =>
  bindActionCreators(
    {
      toggleFilter_ac,
      changePage: (path) => {
        return push(path);
      },
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FilterPanel));
