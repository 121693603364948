import { push } from 'connected-react-router';
import moment from 'moment';
import { getActionButton } from '../components/HealthCall/healthCallSnackBarAction';
import { close_snack_ac, open_snack_ac } from './snack.ac';

export const actionTypes = {
  SET_IS_FETCHING_PATIENT_HEALTHCALL_DATA:
    'SET_IS_FETCHING_PATIENT_HEALTHCALL_DATA',
  RECEIVED_PATIENT_HEALTHCALL_DATA: 'RECEIVED_PATIENT_HEALTHCALL_DATA',
  FAILED_TO_RECEIVE_PATIENT_HEALTHCALL_DATA:
    'FAILED_TO_RECEIVE_PATIENT_HEALTHCALL_DATA',
};

export const fetchPatientHealthCallData_ac = (params) => (dispatch) => {
  dispatch(setIsFetching(true, params.idnStr));
  Promise.all([
    window.sch.post('/api/healthcall:patient:encounters', {
      aa_num: params.aa_num || [],
    }),
    window.sch.post('/api/snapworx/account/active', {
      aa_num: params.aa_num || [],
    }),
    window.sch.post('/api/hdms/account/alerts', {
      aa_num: params.aa_num || [],
    }),
  ])
    .then(([res1, res2, res3]) => {
      console.log({ res2 });
      let data = {
        ...res1,
        is_snap_active: res2.snap_active,
        snap_accounts: res2.snap_accounts,
        account_alerts: res3.alerts,
      };

      let active_status = checkPatientActiveProgram(
        data,
        dispatch,
        params.idnStr
      );

      dispatch({
        idnStr: params.idnStr,
        type: actionTypes.RECEIVED_PATIENT_HEALTHCALL_DATA,
        data: { ...data, active_status },
      });

      let showSnack =
        active_status.is_hc_active ||
        active_status.is_snap_active ||
        (data.account_alerts && data.account_alerts.length > 0);

      if (showSnack) {
        let message = active_status.message;

        if (data.account_alerts && data.account_alerts.length > 0) {
          const alertMessages = data.account_alerts
            .map(
              (alert) =>
                `${alert.alert_type} ${alert.createdate}: ${alert.alertmessage}`
            )
            .join('\n');

          message = message ? `${message}; ${alertMessages}` : alertMessages;
        }

        dispatch(
          open_snack_ac({
            variant: 'notif',
            message: message,
            duration: 30000, // For 10 seconds
            additionalActions: active_status.is_hc_active
              ? [
                  getActionButton('View Ops Forms', () => {
                    dispatch(
                      push(
                        `/dfa/idn/${params.idnStr.replace(
                          / /g,
                          '_'
                        )}/tabs/ops_form`,
                        { from: 'HC_SNACK' }
                      )
                    );
                    dispatch(close_snack_ac({}));
                  }),
                ]
              : undefined,
          })
        );
      }

      // You can dispatch another action here with res2 if needed
    })
    .catch((err) => {
      dispatch({
        idnStr: params.idnStr,
        type: actionTypes.FAILED_TO_RECEIVE_PATIENT_HEALTHCALL_DATA,
        error: true,
      });
    })
    .finally(() => {
      dispatch(setIsFetching(false, params.idnStr));
    });
};

export const legacy_fetchPatientHealthCallData_ac = (params) => (dispatch) => {
  dispatch(setIsFetching(true, params.idnStr));

  window.sch
    .post('/api/healthcall:patient:encounters', { aa_num: params.aa_num || [] })
    .then((res) => {
      dispatch({
        idnStr: params.idnStr,
        type: actionTypes.RECEIVED_PATIENT_HEALTHCALL_DATA,
        data: res,
      });

      let activeProgramAction = checkPatientActiveProgram(
        res,
        dispatch,
        params.idnStr
      );

      if (activeProgramAction) {
        dispatch(activeProgramAction);
      }
    })
    .catch((err) => {
      dispatch({
        idnStr: params.idnStr,
        type: actionTypes.FAILED_TO_RECEIVE_PATIENT_HEALTHCALL_DATA,
        error: true,
      });
    })
    .finally(() => {
      dispatch(setIsFetching(false, params.idnStr));
    });
};

const setIsFetching = (isFetching, idnStr) => ({
  idnStr,
  type: actionTypes.SET_IS_FETCHING_PATIENT_HEALTHCALL_DATA,
  isFetching,
});

const checkPatientActiveProgram = (data, dispatch, idnStr) => {
  let { patientPrograms = [] } = data;
  let activePrg = false;
  for (let prg of patientPrograms) {
    let today = moment();
    let startDateMoment = moment(prg.dateStart);
    let endDateMoment = moment(prg.dateEnd);

    if (!prg.dateStart || !startDateMoment.isValid()) continue;
    if (prg.dateEnd !== null && !endDateMoment.isValid()) continue;

    if (
      prg.program &&
      today.isAfter(startDateMoment) &&
      (prg.dateEnd === null || today.isBefore(endDateMoment))
    ) {
      activePrg = { ...prg };

      break;
    }
  }

  let message = '';
  if (activePrg !== false) {
    message = `${idnStr} is ACTIVE on HC Program "${activePrg.program.name}".`;
  }
  if (data.is_snap_active) {
    message += `${
      message.length ? ', ' : idnStr
    } SNAP Active (Account ${data.snap_accounts
      .map((s) => s.account)
      .join(', ')}).`;
  }
  console.log({ message });

  return {
    is_hc_active: activePrg !== false,
    is_snap_active: data.is_snap_active,
    message,
  };
};
